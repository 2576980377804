import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import Card from "../components/card";
import Layout from "../components/layout";
import { CARD_MENU } from "../constants";
import { navigationState, patientId } from "../recoil/recoil";

const Home = () => {
  const setNavigation = useSetRecoilState(navigationState);
  const setPatientId = useSetRecoilState(patientId);
  // EFFECTS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  useEffect(() => {
    setNavigation([]);
    setPatientId(4)
    window.scrollTo({ top: 0, behavior: "smooth" });
   
  }, []);

  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <Layout className="app-layout">
      <div className="app-content__wrapper">
        <div className="app-content__pro app-content__container flex flex-align-center flex-justify-space-between">
          <div>
            <p className="app-content__paragraphe size20">
              <strong>
                {" "}
                Vos patients présentent des douleurs depuis plus de 3 mois ?{" "}
              </strong>
              Certaines errances de diagnostic impactent la qualité de vie des
              patients et peuvent rendre leur parcours de soin chaotique.
            </p>
            <br />
            <p className="app-content__paragraphe size20">
              Venez vous entraîner sur différents cas patients. Cela vous
              permettra de tester vos connaissances et de vous aider à définir
              un diagnostic précis.
            </p>
            <br />
          
          </div>

          <div className="app-content__pro">
            <img
              src="/images/home/rond-pro-sante.png"
              alt=""
              srcSet={
                "/images/home/rond-pro-sante@2x.png 2x, /images/home/rond-pro-sante@3x.png 3x"
              }
            />
          </div>
        </div>
        <div className="app-content__card flex flex-align-center flex-justify-space-between">
          {CARD_MENU.map((item, i) => (
            <Card
              key={`Card-${i}`}
              pic={item.pic}
              title={item.title}
              info={item.info}
              link={item.link}
              index={i}
            />
          ))}
        </div>
       
      </div>
    </Layout>
  );
};

export default Home;
