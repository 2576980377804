import { useParams } from "react-router";
import TitlePage from "../components/titlePage";
import { getData } from "../helper/utils";
import Quiz from "../components/quiz/quiz";
import { useEffect, useState } from "react";
import Title from "../components/title";
import ButtonNext from "../components/buttonNext";
import { navigationState } from "../recoil/recoil";
import { useRecoilState } from "recoil";
const Examen = () => {
  // STATES
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const [progression, setprogression] = useState<number>(0);
  const [getnavigation,setNavigation] = useRecoilState(navigationState);
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const { id } = useParams() || ("" as any);
  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const patient = getData(id);

   // EFFECTS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  useEffect(() => {
    let _clone  = getnavigation
    setNavigation([..._clone,"examen_clinique"])
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return () => {
      
    }
  }, [])




  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <>
      <TitlePage title="EXAMEN CLINIQUE" />
      {progression === 0 ? (
        <>
          <Title
            icon="icon_interogatoire"
            title="Parmi cette liste d'examens cliniques, choisissez ceux qu'il
              faudrait pratiquer en priorité :"
          />

          <Quiz
            setprogression={setprogression}
            patient={patient}
            section="quiz_1"
          />
        </>
      ) : (
        <>
          <Title icon="icon_examen" title={patient?.rapport.title} />
          <div
            className="app-examen"
            dangerouslySetInnerHTML={{
              __html: patient ? patient?.rapport.paragraph : "",
            }}
          />
        { Number(id)===2 && <img alt="" src="/images/schema_corporel.png" className="responsiveImage"/>}
          {Number(id)!==2 ?  <ButtonNext classname="desktopMargin" path="examen_complementaire" /> :<ButtonNext classname="desktopMargin" path="hypothese_diagnostique" />}
        </>
      )}
    </>
  );
};

export default Examen;
