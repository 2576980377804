import React, { useRef, useState } from "react";
import { IQuestionProps } from "../interface/interface";
import useSound from "use-sound";
//@ts-ignore
import click from "../audios/clic.mp3";
let refclicked = "";
const Question: React.FC<IQuestionProps> = ({
  title,
  response,
  info,
  index,
}) => {
  const [open, setopen] = useState<boolean>(false);
  const [playClick] = useSound(click, { volume: 1 });
  let refclick = useRef<HTMLDivElement>(null);
  let refclickButton= useRef<HTMLDivElement>(null);
  let refImg = useRef<HTMLImageElement>(null);
  //let refclicked = useRef<string>("");

  const closeAll = (e: React.MouseEvent) => {
    playClick();
    if (refclicked === "") {
     
      if (refclick?.current?.classList.contains("isopen")) {
        refclick?.current?.classList.remove("isopen");
        refclickButton?.current?.classList.remove("isopen");
        refImg.current?.setAttribute("src", "/images/icone/open.png");
      } else {
        refclick?.current?.classList.add("isopen");
        refclickButton?.current?.classList.add("isopen");
        refImg.current?.setAttribute("src", "/images/icone/close.png");
      }
     
    } else {
      if (refclicked === e.currentTarget.className) {
        if (refclick?.current?.classList.contains("isopen")) {
          refclick?.current?.classList.remove("isopen");
          refclickButton?.current?.classList.remove("isopen");
          refImg.current?.setAttribute("src", "/images/icone/open.png");
        } else {
          refclick?.current?.classList.add("isopen");
          refclickButton?.current?.classList.add("isopen");
          refImg.current?.setAttribute("src", "/images/icone/close.png");
        }
      } else {
        document.querySelectorAll(".app-question-bloc").forEach((e) => {
          e.classList.remove("isopen");
          refImg.current?.setAttribute("src", "/images/icone/open.png");
        });
        document.querySelectorAll(".app-question-bloc-img").forEach((e) => {
          e?.setAttribute("src", "/images/icone/open.png");
        });
        refclick?.current?.classList.add("isopen");
        refclickButton?.current?.classList.add("isopen");
        refImg.current?.setAttribute("src", "/images/icone/close.png");
      }
    }

    refclicked = e.currentTarget.className;
  };

  return (
    <>
      <div
        onClick={(e: React.MouseEvent) => {
          closeAll(e);
        }}
        ref={refclickButton}
        className={`app-question flex flex-align-center button_${index}`}
      >
        <p className="size14">
          <strong>{title}</strong>
        </p>
        <img
          ref={refImg}
          className="app-question-bloc-img"
          src={`/images/icone/${open ? "close" : "open"}.png`}
          alt=""
          width={30}
          height={30}
        />
      </div>

      <div
        ref={refclick}
        className={`app-question-bloc ${open ? "isopen" : ""}`}
      >
        <div>
          <p className="app-question-bloc__response size14">
            <strong>{response}</strong>
          </p>
          <p className="app-question-bloc__info size14 flex flex-align-center">
            <img
              className="icon"
              src="/images/icone/icon_patient@1x.svg"
              alt=""
            />
            <span
              className="roman55"
              dangerouslySetInnerHTML={{ __html: info ? info : "" }}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default Question;
