import { Link } from "react-router-dom";
import Layout from "../components/layout";

const Header = () => {
  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  return (
    <div
      className="app-header"
      style={{ backgroundImage: "url(/images/banner/change-pain-banner.png)" }}
    >
      <div className="app-header__inner">
        <Layout className="app-layout">
          <div className="app-header__logo">
          <Link to={"/"}>
              <img
                src="/images/logo/change-pain-logo.png"
                alt="logo"
                srcSet={
                  "/images/logo/change-pain-logo@2x.png 2x, /images/logo/change-pain-logo@3x.png 3x"
                }
              />
            </Link>
          </div>
          <div>
          <h1 className="thin35">CAS PATIENTS*</h1>
          <h3><strong>Entraînez-vous</strong></h3></div>
          <p className="card--ml"> *Cas patients fictifs</p>
        </Layout>
      </div>
    </div>
  );
};

export default Header;
