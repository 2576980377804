import React,{ useLocation, useNavigate } from "react-router-dom";
import useSound from "use-sound";
//@ts-ignore
import click from "../audios/clic.mp3";
const ButtonNext = ({
  path,
  classname,
}: {
  path: string;
  classname: string;
}) => {
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const location = useLocation();
  const navigate = useNavigate();
  const [playClick] = useSound(click, { volume: 1 });
  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  return (
    <button
      onClick={() => {
        playClick();
        navigate(
          location.pathname.split("/").slice(0, -1).join("/") + `/${path}`
        );
      }}
      className={classname}
    >
      <strong>
         SUIVANT
      </strong>
     
      {/* <Link
        to={
          
        }
      >
       
      </Link> */}
    </button>
  );
};

export default ButtonNext;
