import { useEffect } from "react";
import {useParams } from "react-router";
import { useRecoilState } from "recoil";
import Block from "../components/block";
import ButtonNext from "../components/buttonNext";
import HeaderPage from "../components/header/headerPage";
import TitlePage from "../components/titlePage";
import { getData } from "../helper/utils";
import { navigationState } from "../recoil/recoil";


const Dossier = () => {
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const { id } = useParams() || ("" as any);
  const [getnavigation,setNavigation] = useRecoilState(navigationState);
  

  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const patient = getData(id);

   // EFFECTS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  useEffect(() => {
    let _clone  = getnavigation
    setNavigation([..._clone,"dossier"])
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
  }, [])



  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  return (
    <>
      <TitlePage title="DOSSIER MÉDICAL"/>
      <HeaderPage />
      <Block
        paragraphe={patient?.histoire.paragraph}
        title={patient?.histoire.title}
        icon="icon_histoire@1x"
      />
      <Block
        paragraphe={patient?.consultation.paragraph}
        title={patient?.consultation.title}
        icon="icon_motif@1x"
      />
      <Block
        paragraphe={patient?.hygiene.paragraph}
        title={patient?.hygiene.title}
        icon="icon_vie@1x"
      />
      <Block
        paragraphe={patient?.antecedant.paragraph}
        title={patient?.antecedant.title}
        icon="icon_antecedents@1x"
      />
      <Block
        paragraphe={patient?.traitement.paragraph}
        title={patient?.traitement.title}
        icon="icon_traitement@1x"
      />
      <Block
        paragraphe={patient?.examen.paragraph}
        title={patient?.examen.title}
        icon="icon_loupe@1x"
      />
      {id==="3" &&  <div className="container_img">
      <img className="responsiveImage exac" alt="" src="/images/examens_complementaires.png"></img>
      </div>}

      <ButtonNext classname="desktopMargin" path="interrogatoire"/>
    </>
  );
};

export default Dossier;


