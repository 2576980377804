import { Link } from "react-router-dom";
import Layout from "./layout";

const Bread = ({
  id,
  path,
}: {
  id: string | undefined;
  path: string | undefined;
}) => {
  return (
    <div className="app-bread">
      <Layout className="app-layout">
        <div className="app-content__wrapper">
          <div className="app-bread__inner">
            {id === "0" ? (
             <p><Link to={"/"}><strong>CAS PATIENTS</strong></Link> {`>`} <strong style={{color:'white'}}>{path}</strong></p>
            ) : (
              <p>
              <Link to={"/"}><strong>CAS PATIENTS</strong></Link>  <strong>{`>`} CAS N°{id} {`>`}</strong>{" "}
                <strong><span className="active">
                  {path === "synthese"
                    ? "synthèse"
                    : path === "dossier"
                    ? "dossier médical"
                    : path === "interrogatoire"
                    ? "interrogatoire patient"
                    : path === "examen_clinique"
                    ? "examen clinique"
                    : path === "examen_complementaire"
                    ? "examens complémentaires"
                    : path === "hypothese_diagnostique"
                    ? "hypothèse diagnostique"
                    : path === "prise_en_charge"
                    ? "prise en charge"
                    : path === "synthese"
                    ? "synthèse"
                    : ""}
                </span></strong>
              </p>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Bread;
