import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Patient from "./routes/patient";
import PreHeader from "./header/preheader";
import Header from "./header/header";
import Footer from "./footer/footer";
import { RecoilRoot } from "recoil";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <PreHeader />
        <Header />

        <Routes>
          <Route path="/" element={<App />} />
          {/* <Route index element={<App />} /> */}
          <Route path="/patient/:id/dossier" element={<Patient />} />
          <Route path="/patient/:id/interrogatoire" element={<Patient />} />
          <Route path="/patient/:id/examen_clinique" element={<Patient />} />
          <Route
            path="/patient/:id/examen_complementaire"
            element={<Patient />}
          />
          <Route
          
            path="/patient/:id/hypothese_diagnostique"
            element={<Patient />}
          />
          <Route path="/patient/:id/prise_en_charge" element={<Patient />} />
          <Route path="/patient/:id/synthese" element={<Patient />} />
         
         
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <h1>404</h1>
              </main>
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
