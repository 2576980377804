import { useParams } from "react-router";
import TitlePage from "../components/titlePage";
import { getData } from "../helper/utils";
import useSound from "use-sound";
import { useNavigate } from "react-router-dom";
import { navigationState } from "../recoil/recoil";
import { useRecoilState } from "recoil";
//@ts-ignore
import click from "../audios/clic.mp3";
import { useEffect } from "react";
const Synthese = () => {
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const { id } = useParams() || ("" as any);
  const navigate = useNavigate();
  const [getnavigation,setNavigation] = useRecoilState(navigationState);
  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const patient = getData(id);
  const [playClick] = useSound(click, { volume: 1 });

   // EFFECTS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  useEffect(() => {
    let _clone  = getnavigation
    setNavigation([..._clone,"synthese"])
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
  }, [])
  
  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <>
      <TitlePage title="SYNTHÈSE" />
      <div className="flex flex-column flex-justify-start">
        <div className="app-bloc-synthese">
          <h3 className="size14">
            <strong>{patient?.synthese.devenir.title}</strong>
          </h3>
          <p className="size14 justify" dangerouslySetInnerHTML={{__html:patient?.synthese.devenir.paragraph ? patient?.synthese.devenir.paragraph: ""}}/>
           
         
        </div>

        <div className="app-bloc-synthese">
          <h3 className="size14">
            <strong>{patient?.synthese.avis.title}</strong>
          </h3>
          <p className="size14 justify" dangerouslySetInnerHTML={{__html:patient?.synthese?.avis?.paragraph ? patient?.synthese?.avis?.paragraph: ""}}/>
        </div>
        <button
          onClick={() => {
            playClick();
            navigate("/");
          }}
        >
          <strong>S'ENTRAÎNER SUR UN AUTRE CAS</strong>
        </button>
      </div>
    </>
  );
};

export default Synthese;

