import Layout from "../components/layout";
import { PATH } from "../constants";

const PreHeader = () => {

  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  return (
    <div className="app-pre-header">
      <div className="app-pre-header__inner">
        <Layout className="app-layout">
          <div className="flex flex-justify-self-end width-100">
            <a
            rel="noreferrer"
            className="flex flex-align-center  externalLink"
            title=""
            href={PATH}
            target="_blank"
          >
            <img
              className="icon"
              width={18}
              height={18}
              src="/images/icon_quitter@1x.svg"
              alt="Quitter"
            />
            <p className='size14'>RETOURNER SUR CHANGEPAIN</p>
          </a>
          </div>
          
        </Layout>
      </div>
    </div>
  );
};

export default PreHeader;
