import { useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Bread from "../components/bread";
import Layout from "../components/layout";
import { navigationState } from "../recoil/recoil";
import Dossier from "./dossier";
import Examen from "./examen_clinique";
import ExamenPlus from "./examen_complementaire";
import Hypothese from "./hypotheses";
import Interrogatoire from "./interrogatoire_patient";
import PriseCharge from "./prise_en_charge";
import Synthese from "./synthese";

const Patient = () => {
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const { id } = useParams() || ("" as any);
  const location = useLocation();
  const getnavigation = useRecoilValue(navigationState);
  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const _path =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  // EFFECTS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <>
      <Bread id={id} path={_path}></Bread>
      <Layout className="app-layout">
        <div className="app-content__wrapper">
          <div className="app-content-medical__inner flex flex-row">
            <div className="app-medical__left">
              <div className="app-medical__left--menu">
                <nav>
                  <ul>
                    <li
                      className={`${
                        getnavigation.includes("dossier") ? "clicked" : ""
                      }  app-medical__left--menu--li size14  ${
                        _path === "dossier"
                          ? ""
                          : _path === "interrogatoire" ||
                            _path === "examen_clinique" ||
                            _path === "examen_complementaire" ||
                            _path === "hypothese_diagnostique" ||
                            _path === "prise_en_charge" ||
                            _path === "synthese"
                          ? "isActive"
                          : "isNotActive"
                      }`}
                    >
                      <Link
                        to={
                          location.pathname.split("/").slice(0, -1).join("/") +
                          "/dossier"
                        }
                      >
                        Dossier médical
                      </Link>
                    </li>
                    <li
                      className={`${
                        getnavigation.includes("interrogatoire")
                          ? "clicked"
                          : ""
                      } app-medical__left--menu--li size14 ${
                        _path === "interrogatoire"
                          ? ""
                          : _path === "examen_clinique" ||
                            _path === "examen_complementaire" ||
                            _path === "hypothese_diagnostique" ||
                            _path === "prise_en_charge" ||
                            _path === "synthese"
                          ? "isActive"
                          : "isNotActive"
                      }`}
                    >
                      <Link
                        to={
                          location.pathname.split("/").slice(0, -1).join("/") +
                          "/interrogatoire"
                        }
                      >
                        Interrogatoire patient
                      </Link>
                    </li>
                    <li
                      className={`${
                        getnavigation.includes("examen_clinique")
                          ? "clicked"
                          : ""
                      } app-medical__left--menu--li size14 ${
                        _path === "examen_clinique"
                          ? ""
                          : _path === "examen_complementaire" ||
                            _path === "prise_en_charge" ||
                            _path === "hypothese_diagnostique" ||
                            _path === "synthese"
                          ? "isActive"
                          : "isNotActive"
                      }`}
                    >
                      <Link
                        to={
                          location.pathname.split("/").slice(0, -1).join("/") +
                          "/examen_clinique"
                        }
                      >
                        Examen clinique
                      </Link>
                    </li>
                  
                  {Number(id)!==2 &&  <li
                      className={`${
                        getnavigation.includes("examen_complementaire")
                          ? "clicked"
                          : ""
                      } app-medical__left--menu--li size14 ${
                        _path === "examen_complementaire"
                          ? ""
                          : _path === "prise_en_charge" ||
                            _path === "synthese" ||
                            _path === "hypothese_diagnostique"
                          ? "isActive"
                          : "isNotActive"
                      }`}
                    >
                      <Link
                        to={
                          location.pathname.split("/").slice(0, -1).join("/") +
                          "/examen_complementaire"
                        }
                      >
                        Examens complémentaires
                      </Link>
                    </li>}
                  
                    <li
                      className={`${
                        getnavigation.includes("hypothese") ? "clicked" : ""
                      } app-medical__left--menu--li size14 ${
                        _path === "hypothese_diagnostique"
                          ? ""
                          : _path === "synthese" || _path === "prise_en_charge"
                          ? "isActive"
                          : "isNotActive"
                      }`}
                    >
                      <Link
                        to={
                          location.pathname.split("/").slice(0, -1).join("/") +
                          "/hypothese_diagnostique"
                        }
                      >
                        Hypothèse diagnostique
                      </Link>
                    </li>
                    <li
                      className={`${
                        getnavigation.includes("prise") ? "clicked" : ""
                      } app-medical__left--menu--li size14 ${
                        _path === "prise_en_charge"
                          ? ""
                          : _path === "synthese"
                          ? "isActive"
                          : "isNotActive"
                      }`}
                    >
                      <Link
                        to={
                          location.pathname.split("/").slice(0, -1).join("/") +
                          "/prise_en_charge"
                        }
                      >
                        Prise en charge
                      </Link>
                    </li>
                    <li
                      className={`${
                        getnavigation.includes("synthese") ? "clicked" : ""
                      } app-medical__left--menu--li size14 ${
                        _path === "synthese" ? "" : "isNotActive"
                      }`}
                    >
                      <Link
                        to={
                          location.pathname.split("/").slice(0, -1).join("/") +
                          "/synthese"
                        }
                      >
                        Synthèse
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className="app-medical__right">
              {_path === "dossier" && <Dossier />}
              {_path === "interrogatoire" && <Interrogatoire />}
              {_path === "examen_clinique" && <Examen />}
              {_path === "examen_complementaire" && <ExamenPlus />}
              {_path === "prise_en_charge" && <PriseCharge />}
              {_path === "synthese" && <Synthese />}
              {_path === "hypothese_diagnostique" && <Hypothese />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Patient;
