const Title = ({
  icon,
  title,
}: {
  icon: string;
  title: string | undefined;
}) => {
  return (
    <div className="section_title flex">
      <img
        className="icon"
        width="30"
        height="30"
        src={`/images/icone/${icon}@1x.svg`}
        alt=""
      ></img>
      <h3 className="size20"><strong>{title}</strong></h3>
    </div>
  );
};

export default Title;
