import axios from "axios";
import { API_URL, KEY } from "../../constants";

export const setRandomArray = (arr: Array<any>) => {
  const newArr = arr.slice();
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr;
};

export const sendScore = (
  action: string,
  id: string,
  id_quiz: string,
  score: number,
  id_case: number
) => {
  
 
   axios
    .post(
      API_URL,
      JSON.stringify({
        action: action,
        user: id,
        id_quiz: id_quiz,
        score: score,
        patient: `usecase_${id_case}`,
        tokken: KEY,
      })
    )
    .then((res) => {
      console.log("error");
     
    });  
};

export const generateUUID = () => {
  var d = new Date().getTime();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
