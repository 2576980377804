import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Quiz from "../components/quiz/quiz";
import ReponseQCU from "../components/quiz/reponseQCU";
import Title from "../components/title";
import TitlePage from "../components/titlePage";
import { getData } from "../helper/utils";
import { navigationState } from "../recoil/recoil";
import { useRecoilState } from "recoil";
const Hypothese = () => {
  // STATES
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const [progression, setprogression] = useState<number>(0);
  const [progressionQuiz, setprogressionQuiz] = useState<number>(0);
  const [getnavigation,setNavigation] = useRecoilState(navigationState);
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const { id } = useParams() || ("" as any);
  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const patient = getData(id);

  // EFFECTS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  useEffect(() => {
    let _clone  = getnavigation
    setNavigation([..._clone,"hypothese"])
    window.scrollTo({ top: 0, behavior: "smooth" });
  
  }, []);

  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <>
      <TitlePage title="HYPOTHÈSE DIAGNOSTIQUE" />

      <>
        <Title
          icon="icon_interogatoire"
          title={`${progressionQuiz===0 ? "Indiquez votre hypothèse diagnostique :" : "Quels arguments ont étayé votre diagnostic ?"} `}
        />

        { progressionQuiz===0 ? (
          <ReponseQCU
            patient={patient}
            setprogressionQuiz={setprogressionQuiz}
          />
        ) : (
          <Quiz
            setprogression={setprogression}
            patient={patient}
            section="quiz_4"
          />
        )}
      </>

      {/*  <>
          <Title
            icon="icon_examen"
            title={patient?.rapport_complementaire.title}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: patient ? patient?.rapport_complementaire.paragraph : "",
            }}
          ></div>
          <ButtonNext classname="desktopMargin" path="prise_en_charge" />
        </> */}
    </>
  );
};

export default Hypothese;
