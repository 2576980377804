import { useEffect, useState } from "react";
import { useParams } from "react-router";
import ButtonNext from "../components/buttonNext";
import Quiz from "../components/quiz/quiz";
import Title from "../components/title";
import TitlePage from "../components/titlePage";
import { getData } from "../helper/utils";
import { navigationState } from "../recoil/recoil";
import { useRecoilState } from "recoil";

const PriseCharge = () => {
  // STATES
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const [progression, setprogression] = useState<number>(0);
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const { id } = useParams() || ("" as any);
  const [getnavigation,setNavigation] = useRecoilState(navigationState);
  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const patient = getData(id);

   // EFFECTS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  useEffect(() => {
    let _clone  = getnavigation
    setNavigation([..._clone,"prise"])
    window.scrollTo({ top: 0, behavior: 'smooth' });
   
  }, [])

  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <>
      <TitlePage title="PRISE EN CHARGE" />
     
        <>
          <Title
            icon="icon_interogatoire"
            title="Parmi ces options de prise en charge, choisissez celle(s) qui vous paraît / paraissent pertinente(s) :"
          />

          <Quiz section='quiz_5' setprogression={setprogression} patient={patient} />
        </>
        {progression===1 && <ButtonNext classname="desktopMargin" path="synthese" />}
      </>
    
  );
};

export default PriseCharge;
