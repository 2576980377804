import {atom} from 'recoil';

export const navigationState = atom<Array<string>>({
    key: 'navigation', 
    default: [],
  });

  export const userId  = atom<string>({
    key: 'userId', 
    default: "",
  });


  export const patientId  = atom<number>({
    key: 'patientId', 
    default: 4,
  });


 