import { useEffect, useState } from "react";
import useSound from "use-sound";
//@ts-ignore
import win from "../../audios/win.wav";
//@ts-ignore
import losse from "../../audios/losse.wav";
import { useRecoilValue } from "recoil";
import { userId } from "../../recoil/recoil";
import { sendScore } from "../helper/utils";
const Resultat = ({
  resultat,
  valider,
  section,
  id
}: {
  resultat: number;
  valider: boolean;
  section: string;
  id: number;
}) => {
  const [state, setstate] = useState(0);
  const [bad, setBad] = useState(0);
  const [playWin] = useSound(win, { volume: 1 });
  const [playLoose] = useSound(losse, { volume: 1 });
  const userID = useRecoilValue(userId);



  

  useEffect(() => {
  
  if(valider){
 if (section !== "quiz_3") {
      if (
        document.getElementsByClassName("isGood").length === resultat &&
        document.getElementsByClassName("isBad").length === 0
      ) {
        playWin();
      } else {
        playLoose();
      }
    }

    setstate(document.getElementsByClassName("isGood").length);
    setBad(document.getElementsByClassName("isBad").length);


    // SAVE SCORE
    let _result =document.getElementsByClassName("isGood").length-document.getElementsByClassName("isBad").length

    const score =Math.round((_result<0 ? 0 : _result / resultat) * 100)
    if(userID!==""){
          sendScore("ADD_SCORE",userID,section,score,id)

    }
   
  }
   

   
  }, [valider]);

  return (
    <>
      {section === "quiz_3" && bad > 0 ? (
        <>
          <p className="isBad">
            <strong>Mauvaise réponse, choisissez un autre diagnostic</strong>
          </p>
        </>
      ) : (
        <p id="result" className="size20 app-result">
          <strong>
            RÉSULTAT : {state - bad < 0 ? 0 : state - bad}/{resultat}{" "}
            {bad > 0 && `(${bad} malus)`}
          </strong>
        </p>
      )}
    </>
  );
};

export default Resultat;
