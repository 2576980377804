import React, { useEffect, useRef, useState } from "react";
import { IQuizProps } from "../../interface/interface";
import Resultat from "./resultat";
import "./style/reponse.css";
import useSound from "use-sound";
//@ts-ignore
import win from "../../audios/win.wav";
//@ts-ignore
import losse from "../../audios/losse.wav";
//@ts-ignore
import activeAudio from "../../audios/pop-down.mp3";
//@ts-ignore
import click from "../../audios/clic.mp3";
import { setRandomArray } from "../helper/utils";
import { useParams } from "react-router-dom";
interface Iprops {
  patient: any;
  setprogressionQuiz:Function
}
const ReponseQCU: React.FC<Iprops> = ({ patient,setprogressionQuiz }) => {
  // STATE
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const [valider, setvalider] = React.useState<boolean>(false);
  const [checked, setchecked] = useState<string>("");
  const [count, setcount] = useState<number>(0);
  const [progression, setprogression] = useState<number>(0);
  const [playActive] = useSound(activeAudio, { volume: 1 });
  const [playClick] = useSound(click, { volume: 1 });
  const [playWin] = useSound(win, { volume: 1 });
  const [playLoose] = useSound(losse, { volume: 1 });
  const { id } = useParams() || ("" as any);
  // REFS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const refgoodAnswer = useRef<string>("QUESTION_0");
  const reponse = useRef<any>([]);
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  // FUNCTION
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const giveAnotherChange = () => {
    
     setTimeout(() => {
      clearTimeout();
      setcount(0);
      setchecked("");
      setvalider(false);
    }, 2000); 
  };

  const setValide = () => {
    setvalider(true);
    
    if (refgoodAnswer.current === checked) {
      playWin()
      setprogression(1);
    } else {
      playLoose()
      giveAnotherChange();
    }
  };

  const handleChange = (e: any) => {
    playActive();
    setchecked(e.target.value);
    setcount(1);
  };

  useEffect(() => {
    reponse.current = setRandomArray(patient["quiz_3"]?.[0]?.answsers);
    reponse.current.forEach((element:any,index:number) => {
      if(element.choice === "1"){
        refgoodAnswer.current = `QUESTION_${index}`;
      }
    });
    
  }, [])
  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <div className="app-quiz-label qcu">
      {progression !== 2 && reponse?.current?.map(
        (item: IQuizProps, index: number) => {
          return (
            <div key={index}>
              <div className="container">
                <label htmlFor={`QUESTION_${index}`}>
                  <input
                    id={`QUESTION_${index}`}
                    type="radio"
                    checked={`QUESTION_${index}` === checked ? true : false}
                    onChange={handleChange}
                    disabled={valider}
                    name="quiz"
                    value={`QUESTION_${index}`}
                  />
                  {item.title}
                  <span
                    className={`checkmark ${
                      item.choice === "1" &&
                      valider &&
                      `QUESTION_${index}` === checked
                        ? "isGood"
                        : item.choice === "0" &&
                          valider &&
                          `QUESTION_${index}` === checked
                        ? "isBad"
                        : ""
                    }`}
                  />
                </label>
              </div>
              {progression === 1 && item.feedback!=="" && (
                <div className={`app-question-bloc size14 container ${valider ? "isopen" : ""}`}>
                  <div className="flex flex-align-center">
                    <img
                      className="icon"
                      src="/images/icone/icon_patient@1x.svg"
                      alt=""
                    />
                    <div>
                      <p
                        className="app-question-bloc__response quiz open size14"
                        dangerouslySetInnerHTML={{ __html: `<strong>${item.feedback}</strong> ${item.reference==="" ? "" : "("+ item.reference+")" } ` }}
                      ></p>
                    
                    </div>
                  </div>
                </div>
                
              )}
              {progression === 1 && item.feedback==="" && item.reference!=="" && (
                <div className={`app-question-bloc size14 container ${valider ? "isopen" : ""}`}>
                  <div className="flex flex-align-center">
                    <img
                      className="icon"
                      src="/images/icone/icon_patient@1x.svg"
                      alt=""
                    />
                    <div>
                      <p
                        className="app-question-bloc__response quiz open size14"
                        dangerouslySetInnerHTML={{ __html: `${item.reference==="" ? "" : "("+ item.reference+")" } ` }}
                      ></p>
                    
                    </div>
                  </div>
                </div>
                
              )}
            </div>
          );
        }
      )}

      {valider && <Resultat id={id? Number(id) : 0} valider={valider} resultat={1} section="quiz_3" />}

      {(!valider || progression === 1) && (
        <button
          className={`desktopMargin  ${
            count > 0 ? "isDisplayed" : "isNotDisplayed"
          }`}
          onClick={() => {
            playClick();
            progression === 0
              ? setValide()
              : setprogressionQuiz(1)
          }}
        >
          <strong>{progression === 0 ? "valider" : "suivant"}</strong>
        </button>
      )}
      {/* {progression === 2 && (<>
        <Title
            icon="icon_interogatoire"
            title="Quels arguments ont étayé votre diagnostic ?"
          />

          <Quiz
            setprogression={setprogression}
            patient={patient}
            section="quiz_4"
          />
      </>)} */}
    </div>
  );
};
export default ReponseQCU;
/* navigate(
                  `${location.pathname
                    .split("/")
                    .slice(0, -1)
                    .join("/")}/prise_en_charge`
                ); */