import { useParams } from "react-router";
import { getData } from "../../helper/utils";

const HeaderPage = () => {
  let { id } = useParams() || ("" as any);
  let patient = getData(id);
  return (
    <div>
      <div className="header-page__photo flex flex-align-center">
        <img src={`/images/home/perso_${id}.png`} alt="" />
        <div className="header-page-content">
          <h3 className="size20"><strong>Profil patient</strong></h3>
          <p className="size14">{patient?.profil.title}</p>
          <p className="size14">{patient?.profil.description}</p>
        </div>
      </div>
    </div>
  );
};

export default HeaderPage;
