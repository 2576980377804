import React from 'react';
const Block = ({
  title,
  icon,
  paragraphe,
}: {
  title: string | undefined;
  icon: string | undefined;
  paragraphe: string | undefined;
}) => {
  return (
    <>
      <div className="flex bloc-header">
        <img
          className="icon"
          width="30"
          src={`/images/${icon}.svg`}
          alt=""
        ></img>
        <h3 className="size20">
          <strong>{title}</strong>
        </h3>
      </div>

      <p
        className="bloc-paragraph size14 justify"
        dangerouslySetInnerHTML={{ __html: paragraphe ? paragraphe : "" }}
      ></p>
    </>
  );
};

export default Block;
