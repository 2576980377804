import { useEffect, useState } from "react";
import { useParams } from "react-router";
import ButtonNext from "../components/buttonNext";
import Quiz from "../components/quiz/quiz";
import Title from "../components/title";
import TitlePage from "../components/titlePage";
import { getData } from "../helper/utils";
import { navigationState } from "../recoil/recoil";
import { useRecoilState } from "recoil";
const ExamenPlus = () => {
  // STATES
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const [progression, setprogression] = useState<number>(0);
  const [getnavigation,setNavigation] = useRecoilState(navigationState);
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const { id } = useParams() || ("" as any);
  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const patient = getData(id);

   // EFFECTS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  useEffect(() => {
    let _clone  = getnavigation
    setNavigation([..._clone,"examen_complementaire"])
    window.scrollTo({ top: 0, behavior: 'smooth' });
   
  }, [])

  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <>
      <TitlePage title="EXAMENS complémentaires" />
      {progression === 0 ? (
        <>
          <Title
            icon="icon_interogatoire"
            title="Parmi cette liste d'examens, choisissez ceux qu'il faudrait prescrire au patient :"
          />

          <Quiz section='quiz_2' setprogression={setprogression} patient={patient} />
        </>
      ) : (
        <>
          <Title icon="icon_examen" title={patient?.rapport_2.title} />
          <div
           className="app-examen"
            dangerouslySetInnerHTML={{
              __html: patient ? patient?.rapport_2.paragraph : "",
            }}
          ></div>
          <ButtonNext classname="desktopMargin" path="hypothese_diagnostique" />
        </>
      )}
    </>
  );
};

export default ExamenPlus;
