import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import useSound from "use-sound";

//@ts-ignore
import click from "../audios/clic.mp3";
import { patientId, userId } from "../recoil/recoil";
import { generateUUID, sendScore } from "./helper/utils";

const Card = ({
  pic,
  title,
  info,
  link,
  index,
}: {
  pic: string;
  title: string;
  info: string;
  link: string;
  index: number;
}) => {
  const navigate = useNavigate();
  const [playClick] = useSound(click, { volume: 1 });
  const setUserId = useSetRecoilState(userId);
  const setPatientId = useSetRecoilState(patientId);

  const addUser = (index: number) => {
    const id = generateUUID();
    const userCase = index + 1;
    setUserId(id);
    setPatientId(index);
    sendScore("ADD_USER", id, "", 0, userCase);
  };



  return (
    <div className="card">
      <img className={`photo photo_${index}`} alt="" src={pic}></img>
      <div className="card--body">
        <p
          className="card--title size17"
          dangerouslySetInnerHTML={{ __html: `<strong>${title}</strong>` }}
        />
        <p
          className="card--info size12"
          dangerouslySetInnerHTML={{
            __html: `<span class="thin46i">${info}</span>`,
          }}
        />
        <button
          type="button"
          onClick={() => {
            playClick();
            addUser(index);
            navigate(link, { replace: true });
          }}
        >
          <strong>Consulter</strong>
        </button>
      </div>
    </div>
  );
};

export default Card;
