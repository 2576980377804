import React from "react";
import { IQuizProps } from "../../interface/interface";
import "./style/reponse.css";
import useSound from "use-sound";

//@ts-ignore
import activeAudio from "../../audios/pop-down.mp3";
//@ts-ignore
import playOffAudio from "../../audios/pop-up-off.mp3";
interface Iprops {
  question: IQuizProps;
  valider: boolean;
  compteur: Function;
  showInfo: boolean;
  section: string | undefined;
}
const Reponse: React.FC<Iprops> = ({
  question,
  valider,
  compteur,
  showInfo,
  section,
}) => {
  // STATE
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const [checked, setchecked] = React.useState(false);
  const [playActive] = useSound(activeAudio, { volume: 1 });
  const [playOff] = useSound(playOffAudio, { volume: 0.5 });
  // VAR
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const handleChange = (e: any) => {
    checked ? playOff() : playActive();
    setchecked(e.target.checked);
    !checked ? compteur(1) : compteur(-1);
  };
  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <>
      <label className="container">
        <input
          id={question.title}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={valider}
          name="quiz"
         
        />
        <label htmlFor={question.title}>{question.title}</label>
        <span
          className={`checkmark ${
            valider && checked && question.choice === "1" ? "isGood" : ""
          } ${valider && checked && question.choice === "0" ? "isBad" : ""} ${
            valider &&
            !checked &&
            question.choice === "1" &&
            section !== "quiz_3"
              ? "isNoSelect"
              : ""
          } ${
            valider && !checked && question.choice === "0" ? "isNothing" : ""
          } `}
        />
      </label>
      {showInfo && (
        <div className={`app-question-bloc ${valider ? "isopen" : ""}`}>
          <div className="flex flex-align-center">
            <img
              className="icon"
              src="/images/icone/icon_patient@1x.svg"
              alt=""
            />
            <div>
              <p
                className="app-question-bloc__response quiz open size14"
                dangerouslySetInnerHTML={{ __html: `<strong>${question.feedback}</strong> ${question.reference==="" ? "" : "("+ question.reference+")" } ` }}
              ></p>
           
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Reponse;
