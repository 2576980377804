import React, { useEffect, useRef, useState } from "react";
import { IQuizProps } from "../../interface/interface";
import Reponse from "./reponseQCM";
import Resultat from "./resultat";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useSound from "use-sound";
//@ts-ignore
import click from "../../audios/clic.mp3";
import { setRandomArray } from "../helper/utils";

const Quiz = ({
  patient,
  setprogression,
  section,
}: {
  patient: any;
  setprogression: Function;
  section: string;
}) => {
  // HOOKS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const [valider, setvalider] = React.useState<boolean>(false);
  const [count, setcount] = useState<number>(0);
  const [sectionquiz, setsection] = useState<string>(section);
  const location = useLocation();
  const navigate = useNavigate();
  const [playClick] = useSound(click, { volume: 1 });
  const { id } = useParams() || ("" as any);

  // REFS
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const reponse = useRef<any>([]);
  // FUNCTION
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  const Compteur = (x: number) => {
    setcount(count + x);
  };

  let nbResultat = 0;
  useEffect(() => {
    let _arr = [];
    let _arr2 = [];
    let _arr3 = [];
    if (sectionquiz === "quiz_2" && Number(id) === 1) {
      for (let i = 0; i < 6; i++) {
        _arr.push(patient[sectionquiz]?.[0]?.answsers[i]);
      }
      for (let j = 6; j < 8; j++) {
        _arr2.push(patient[sectionquiz]?.[0]?.answsers[j]);
      }
      reponse.current = [...setRandomArray(_arr), ...setRandomArray(_arr2)];
    } else if (sectionquiz === "quiz_2" && Number(id) === 4) {
      for (let i = 0; i < 4; i++) {
        _arr.push(patient[sectionquiz]?.[0]?.answsers[i]);
      }
      for (let j = 4; j < 6; j++) {
        _arr2.push(patient[sectionquiz]?.[0]?.answsers[j]);
      }
      for (let k = 6; k < 7; k++) {
        _arr3.push(patient[sectionquiz]?.[0]?.answsers[k]);
      }
      reponse.current = [
        ...setRandomArray(_arr),
        ...setRandomArray(_arr2),
        ...setRandomArray(_arr3),
      ];
    } else if (sectionquiz === "quiz_2" && Number(id) === 3) {
      for (let i = 0; i < 2; i++) {
        _arr.push(patient[sectionquiz]?.[0]?.answsers[i]);
      }
      for (let j = 2; j < 5; j++) {
        _arr2.push(patient[sectionquiz]?.[0]?.answsers[j]);
      }
      for (let k = 5; k < 6; k++) {
        _arr3.push(patient[sectionquiz]?.[0]?.answsers[k]);
      }
      reponse.current = [
        ...setRandomArray(_arr),
        ...setRandomArray(_arr2),
        ...setRandomArray(_arr3),
      ];
    } else {
      reponse.current = setRandomArray(patient[sectionquiz]?.[0]?.answsers);
    }
    return () => {};
  }, []);

  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <div className="app-quiz-label">
      {reponse?.current?.map((item: IQuizProps, index: number) => {
        const local = item.choice === "1" ? nbResultat++ : nbResultat;
        return (
          <React.Fragment key={`LABEL_${index}`}>
            {sectionquiz === "quiz_2" && index === 0 && Number(id) === 1 ? (
              <p className="app-ssTitle">Biologie :</p>
            ) : (
              <></>
            )}
            {sectionquiz === "quiz_2" && index === 6 && Number(id) === 1 ? (
              <>
                <br />
                <p className="app-ssTitle">Imagerie :</p>
              </>
            ) : (
              <></>
            )}

            {sectionquiz === "quiz_2" && index === 0 && Number(id) === 4 ? (
              <p className="app-ssTitle">Biologie :</p>
            ) : (
              <></>
            )}
            {sectionquiz === "quiz_2" && index === 4 && Number(id) === 4 ? (
              <>
                <br />
                <p className="app-ssTitle">Imagerie :</p>
              </>
            ) : (
              <></>
            )}
            {sectionquiz === "quiz_2" && index === 6 && Number(id) === 4 ? (
              <>
                <br />
                <p className="app-ssTitle">Électrophysiologie :</p>
              </>
            ) : (
              <></>
            )}


            {sectionquiz === "quiz_2" && index === 0 && Number(id) === 3 ? (
              <p className="app-ssTitle">Biologie :</p>
            ) : (
              <></>
            )}
            {sectionquiz === "quiz_2" && index === 2 && Number(id) === 3 ? (
              <>
                <br />
                <p className="app-ssTitle">Imagerie :</p>
              </>
            ) : (
              <></>
            )}
            {sectionquiz === "quiz_2" && index === 5 && Number(id) === 3 ? (
              <>
                <br />
                <p className="app-ssTitle">Électrophysiologie :</p>
              </>
            ) : (
              <></>
            )}



            {/* {sectionquiz === "quiz_2" ? ( */}
            <div key={index}>
              <Reponse
                question={{
                  title: item.title,
                  feedback: item.feedback,
                  reference: item.reference,
                  choice: item.choice,
                }}
                valider={valider}
                compteur={Compteur}
                showInfo={true}
                section={sectionquiz}
              />
            </div>
          </React.Fragment>
        );
      })}
      <>
        <div style={{ visibility: valider ? "visible" : "hidden" }}>
          {
            <Resultat
              valider={valider}
              resultat={nbResultat}
              section={sectionquiz}
              id={id ? Number(id) : 0}
            />
          }
        </div>

        <>
          <button
            className={`desktopMargin  ${
              count > 0 ? "isDisplayed" : "isNotDisplayed"
            }`}
            onClick={() => {
              playClick();
              !valider
                ? setvalider(true)
                : sectionquiz === "quiz_4"
                ? navigate(
                    `${location.pathname
                      .split("/")
                      .slice(0, -1)
                      .join("/")}/prise_en_charge`
                  )
                : sectionquiz === "quiz_5"
                ? navigate(
                    `${location.pathname
                      .split("/")
                      .slice(0, -1)
                      .join("/")}/synthese`
                  )
                : setprogression(1);
            }}
          >
            <strong>{!valider ? "valider" : "Suivant"}</strong>
          </button>
        </>
      </>
    </div>
  );
};

export default Quiz;
