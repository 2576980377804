import Home from "./routes/home";

function App() {
  // RETURN
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
