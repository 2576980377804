import { useRecoilValue } from "recoil";
import Layout from "../components/layout";
import { _code } from "../constants";
import { patientId } from "../recoil/recoil";

const Footer = () => {
  const _id = useRecoilValue(patientId);

  return (
    <div className="app-footer">
      <div className="app-footer__inner">
        <Layout className="app-layout">
          <div className="footer-site_logo">
            <a href="https://grunenthal.com/" className="footer-site__logo">
              <img
                alt=""
                className="footer-site__logo"
                src="https://www.changepain.fr/-/media/projects/shared/logos/grt/grunenthal-altes-logo-footer.svg"
                width="129"
                height=""
              />
            </a>
          </div>
          <div className="footer-site__links ">
            <div className="size14 footer-site__reference">{_code[_id]}</div>
            <div className="size14 footer-site__copyright">
              Grünenthal 2021 ©
              <nav className="footer__navigation-inline size14">
                <a
                  rel="noreferrer"
                  title="Conditions d’utilisation"
                  href="https://www.changepain.com/fr-fr/conditions-d-utilisation"
                  target="_blank"
                >
                  Conditions d’utilisation
                </a>
                <a
                  rel="noreferrer"
                  title="Renseignement éditeur"
                  href="https://www.changepain.com/fr-fr/renseignements-editeur"
                  target="_blank"
                >
                  Renseignement éditeur
                </a>
                <a
                  rel="noreferrer"
                  title="Politique de confidentialité"
                  href="https://www.changepain.com/fr-fr/declaration-de-confidentialite"
                  target="_blank"
                >
                  Politique de confidentialité
                </a>
              </nav>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default Footer;
